import React, { useState } from 'react';
import { products } from '../../Product';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Button = ({ menuItem, filterItems, setItems }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("Select Your Type");

  const handleSelection = (val) => {
    setSelectedType(val);
    filterItems(val);
    setIsOpen(false); // Close accordion
  };

  return (
    <div>
      {/* Mobile View Accordion */}
      <div className="md:hidden w-full px-4">
        <div
          className="flex justify-between items-center bg-[#30465a] px-6 py-3 border border-gray-300 rounded-lg shadow-md cursor-pointer text-white"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="text-lg font-semibold">{selectedType}</span>
          <div className="ml-2">
            {isOpen ? <FaChevronUp className="text-white" /> : <FaChevronDown className="text-white" />}
          </div>
        </div>

        {isOpen && (
          <div className="mt-2 bg-white border border-gray-200 rounded-lg shadow-md p-4">
            {menuItem.map((val, index) => (
              <button
                key={index}
                className="w-full text-left px-4 py-2 text-gray-700 font-medium hover:bg-orange-500 hover:text-white rounded-md transition-all duration-300"
                onClick={() => handleSelection(val)}
              >
                {val}
              </button>
            ))}
            <button
              className="w-full text-left px-4 py-2 text-gray-700 font-medium hover:bg-orange-500 hover:text-white rounded-md transition-all duration-300"
              onClick={() => {
                setSelectedType("All");
                setItems(products);
                setIsOpen(false);
              }}
            >
              All
            </button>
          </div>
        )}
      </div>

      {/* Desktop View Buttons */}
      <div className="hidden md:flex flex-wrap justify-center gap-6 mt-4">
        {menuItem.map((val, index) => (
          <button
            key={index}
            className="btn px-6 py-3 border-2 border-white text-orange-500 bg-white rounded-full text-sm font-semibold shadow-md transition-all duration-300 hover:bg-orange-500 hover:text-white focus:outline-none"
            onClick={() => filterItems(val)}
          >
            {val}
          </button>
        ))}
        <button
          className="btn px-6 py-3 border-2 border-white text-orange-500 bg-white rounded-full text-sm font-semibold shadow-md transition-all duration-300 hover:bg-orange-500 hover:text-white focus:outline-none"
          onClick={() => setItems(products)}
        >
          All
        </button>
      </div>
    </div>
  );
};

export default Button;
