import imagg1 from "./assets/Images/1l.webp";
import imagg2 from "./assets/Images/2l.webp";
import imagg3 from "./assets/Images/3l.webp";
import imagg4 from "./assets/Images/4l.webp";
import imagg5 from "./assets/Images/5l.webp";
import imagg6 from "./assets/Images/6l.webp";
import imagg7 from "./assets/Images/7l.webp";
import imagg8 from "./assets/Images/8l.webp";
import imagg9 from "./assets/Images/9l.webp";
import imagg10 from "./assets/Images/10l.webp";
import imagg11 from "./assets/Images/11l.webp";
import imagg12 from "./assets/Images/12l.webp";
import imagg13 from "./assets/Images/13l.webp";
import imagg14 from "./assets/Images/14l.webp";
import imagg15 from "./assets/Images/15l.webp";
import imagg16 from "./assets/Images/16l.webp";
import imagg17 from "./assets/Images/17l.webp";
import imagg18 from "./assets/Images/18l.webp";
import imagg19 from "./assets/Images/19l.webp";
import imagg20 from "./assets/Images/20l.webp";
import imagg21 from "./assets/Images/21l.webp";
import imagg22 from "./assets/Images/33l.webp";
import imagg23 from "./assets/Images/34l.webp";
import imagg24 from "./assets/Images/47l.webp";
import imagg25 from "./assets/Images/22l.webp";
import imagg26 from "./assets/Images/imgA1.webp";
import imagg27 from "./assets/Images/imgA5.webp";
import imagg28 from "./assets/Images/imgA7.webp";
import imagg29 from "./assets/Images/11511.jpg";
import imagg30 from "./assets/Images/mmimg2.webp";
import imagg31  from "./assets/Images/imgA6.webp";
import imagg32 from "./assets/Images/mimg4.webp";
import imagg33 from "./assets/Images/11027img.jpg";
import imagg34 from "./assets/Images/glossgry.jpg";
import imagg35 from "./assets/Images/gi3.webp";
import imagg36 from "./assets/Images/gi4.webp";


import image1 from "./assets/Images/ga4002.webp";
import image2 from "./assets/Images/GA-4036.png";
import image3 from "./assets/Images/4403.webp";
import image4 from "./assets/Images/GA-4006.png";
import image5 from "./assets/Images/GA-4704.png";
import image6 from "./assets/Images/4601.webp";
import image7 from "./assets/Images/Ga4011.webp";
import image8 from "./assets/Images/GA-4008.webp";
import image9 from "./assets/Images/ga4039.png";
import image10 from "./assets/Images/4607.webp";
import image11 from "./assets/Images/4037.webp";
import image12 from "./assets/Images/4019.webp";
import image13 from "./assets/Images/4415.webp";
import image14 from "./assets/Images/4608.webp";
import image15 from "./assets/Images/4610.webp";
import image16 from "./assets/Images/4409.png";
import image17 from "./assets/Images/4420.webp";
import image18 from "./assets/Images/4807.webp";
import image19 from "./assets/Images/4803.webp";
import image20 from "./assets/Images/4806.webp";
import image21 from "./assets/Images/4811.webp";
import image22 from "./assets/Images/4813.webp";
import image23 from "./assets/Images/4606.webp";
import image24 from "./assets/Images/GA-4129.webp";
import image25 from "./assets/Images/GA-4111.webp";
import image26 from "./assets/Images/GA-4128.webp";
import image27 from "./assets/Images/GA-4143.webp";
import image28 from "./assets/Images/GA-4112.webp";
import image29 from "./assets/Images/11511-Feather-Blue.webp";
import image30 from "./assets/Images/11505-Cashmere.jpg";
import image31 from "./assets/Images/11502.webp";
import image32 from "./assets/Images/11506Metallic-Grey.webp";
import image33 from "./assets/Images/11027-Revival-Green.webp";
import image34 from "./assets/Images/11013-Light-Grey.webp";
import image35 from "./assets/Images/11010-Black.webp";
import image36 from "./assets/Images/11014-Metallic-Silver.webp";




import img1 from "./assets/Images/imgA1.webp";
import img2 from "./assets/Images/imgA5.webp";
import img3 from "./assets/Images/imgA7.webp";
import img4 from "./assets/Images/imgA6.webp";
import img5 from "./assets/Images/imgA19.webp";
import img6 from "./assets/Images/4601img.png";
import img7 from "./assets/Images/imgA2.webp";
import img8 from "./assets/Images/imgA3.webp";
import img9 from "./assets/Images/imgA4.webp";
import img10 from "./assets/Images/4607img.webp";
import img11 from "./assets/Images/4037img.png";
import img12 from "./assets/Images/4001.png";
import img13 from "./assets/Images/img555.webp";
import img14 from "./assets/Images/4608img.webp";
import img15 from "./assets/Images/4610img.webp";
import img16 from "./assets/Images/imgA9.webp";
import img17 from "./assets/Images/4420img.png";
import img18 from "./assets/Images/imgA10.webp";
import img19 from "./assets/Images/imgA11.webp";
import img20 from "./assets/Images/imgA12.webp";
import img21 from "./assets/Images/imgA13.webp";
import img22 from "./assets/Images/imgA14.webp";
import img23 from "./assets/Images/imgA15.webp";
import img24 from "./assets/Images/img1.png";
import img25 from "./assets/Images/img2.png";
import img26 from "./assets/Images/img3.png";
import img27 from "./assets/Images/img4.png";
import img28 from "./assets/Images/img5.png";
import img29 from "./assets/Images/11511.jpg";
import img30 from "./assets/Images/mmimg2.webp";
import img31 from "./assets/Images/11502im.jpg";
import img32 from "./assets/Images/mimg4.webp";
import img33 from "./assets/Images/11027img.jpg";
import img34 from "./assets/Images/glossgry.jpg";
import img35 from "./assets/Images/gi3.webp";
import img36 from "./assets/Images/gi4.webp";

const newDescription = ` The frame is 18 mm thick and made from MDF, HDHMR, BWR Ply, BWP Ply, or Bolio Board. 
 A 0.8 mm laminate sheet (liner) is applied to the inner wall of the board and the backside of the shutter board.
 The backside of the cabinet features 6 mm MDF, HDHMR, BWR Ply, BWP Ply, or Bolio Board with a 0.8 mm laminate.`;

const newDescription2 = ` Soft-close 165° hinges
 Wooden inner drawer with telescopic soft-close slides (60 kg capacity, zinc-coated)`;

export const products = [
  {
    id: 1,
    name: "GA-4002",
    price: 101,
    image: image1,
    img: img1,
    imgg: imagg1 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4002",
  },
  {
    id: 2,
    name: " GA-4036",
    price: 101,
    image: image2,
    img: img2,
    imgg: imagg2 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4036",
  },
  {
    id: 3,
    name: "GA-4403",
    price: 101,
    image: image3,
    img: img3,
    imgg: imagg3 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4403",
  },
  {
    id: 4,
    name: "GA-4006",
    price: 101,
    image: image4,
    img: img4,
    imgg: imagg4 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4006",
  },
  {
    id: 5,
    name: "GA-4704",
    price: 101,
    image: image5,
    img: img5,
    imgg: imagg5 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4704",
  },

  {
    id: 6,
    name: "GA-4601",
    price: 101,
    image: image6,
    img: img6,
    imgg: imagg6 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4601",
  },

  {
    id: 7,
    name: "GA-4010",
    price: 101,
    image: image7,
    img: img7,
    imgg: imagg7 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4010",
  },
  {
    id: 8,
    name: "GA-4008",
    price: 101,
    image: image8,
    img: img8,
    imgg: imagg8 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4008",
  },
  {
    id: 9,
    name: "GA-4039",
    price: 101,
    image: image9,
    img: img9,
    imgg: imagg9 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4039",
  },
  {
    id: 10,
    name: "GA-4607",
    price: 101,
    image: image10,
    img: img10,
    imgg: imagg10 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4607",
  },
  {
    id: 11,
    name: "GA-4037",
    price: 101,
    image: image11,
    img: img11,
    imgg: imagg11 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4037",
  },
  {
    id: 12,
    name: "GA-4019",
    price: 101,
    image: image12,
    img: img12,
    imgg: imagg12 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4019",
  },
  {
    id: 13,
    name: "GA-4415",
    price: 101,
    image: image13,
    img: img13,
    imgg: imagg13 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4415",
  },
  {
    id: 14,
    name: "GA-4608",
    price: 101,
    image: image14,
    img: img14,
    imgg: imagg14 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4608",
  },
  {
    id: 15,
    name: "GA-4610",
    price: 101,
    image: image15,
    img: img15,
    imgg: imagg15 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4610",
  },
  {
    id: 16,
    name: "GA-4409",
    price: 101,
    image: image16,
    img: img16,
    imgg: imagg16 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4409",
  },
  {
    id: 17,
    name: "GA-4420",
    price: 101,
    image: image17,
    img: img17,
    imgg: imagg17 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4420",
  },
  {
    id: 18,
    name: "GA-4807",
    price: 101,
    image: image18,
    img: img18,
    imgg: imagg18 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4807",
  },
  {
    id: 19,
    name: "GA-4803",
    price: 101,
    image: image19,
    img: img19,
    imgg: imagg19 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4803",
  },
  {
    id: 20,
    name: "GA-4806",
    price: 101,
    image: image20,
    img: img20,
    imgg: imagg20 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4806",
  },
  
  {
    id: 21,
    name: "GA-4811",
    price: 101,
    image: image21,
    img: img21,
    imgg: imagg21 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4811",
  },

  {
    id: 22,
    name: "GA-4813",
    price: 101,
    image: image22,
    img: img22,
    imgg: imagg22 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4813",
  },

  {
    id: 23,
    name: "GA-4606",
    price: 101,
    image: image23,
    img: img23,
    imgg: imagg23 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4606",
  },
  {
    id: 24,
    name: "GA-4129",
    price: 101,
    image: image24,
    img: img24,
    imgg: imagg24 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4129",
  },

  {
    id: 25,
    name: "GA-4111",
    price: 101,
    image: image25,
    img: img25,
    imgg: imagg25 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4111",
  },

  {
    id: 26,
    name: "GA-4128",
    price: 101,
    image: image26,
    img: img26,
    imgg: imagg26 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4128",
  },

  {
    id: 27,
    name: "GA-4143",
    price: 101,
    image: image27,
    img: img27,
    imgg: imagg27 ,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4143",
  },
  {
    id: 28,
    name: "GA-4112",
    price: 101,
    image: image28,
    imgg: imagg28 ,
    img: img28,
    category: "AcryLance",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "GA-4112",
  },

  {
    id: 29,
    name: "AM 11511 SM-Feather Blue",
    price: 101,
    image: image29,
    img: img29,
    imgg: imagg29 ,
    category: "SuperMatt",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "AM 11511 SM-Feather Blue",
  },

  {
    id: 30,
    name: "AM 11505 SM-Cashmere",
    price: 101,
    image: image30,
    img: img30,
    imgg: imagg30 ,
    category: "SuperMatt",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "AM 11505 SM-Cashmere",
  },

  {
    id: 31,
    name: "AM-11502-SM-Cappuccino",
    price: 101,
    image: image31,
    img: img31,
    imgg: imagg31 ,
    category: "SuperMatt",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "AM-11502-SM-Cappuccino",
  },

  {
    id: 32,
    name: "AM 11506 SM - Metallic Grey",
    price: 101,
    image: image32,
    img: img32,
    imgg: imagg32 ,
    category: "SuperMatt",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "AM 11506 SM - Metallic Grey",
  },

  {
    id: 33,
    name: "AM 11027 HG - Revival Green",
    price: 101,
    image: image33,
    img: img33,
    imgg: imagg33 ,
    category: "LamiGloss",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "AM 11027 HG - Revival Green",
  },
  {
    id: 34,
    name: "AM 11013 HG - Light Grey",
    price: 101,
    image: image34,
    img: img34,
    imgg: imagg34 ,
    category: "LamiGloss",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "AM 11013 HG - Light Grey",
  },

  {
    id: 35,
    name: "AM 11010 HG - Black",
    price: 101,
    image: image35,
    img: img35,
    imgg: imagg35 ,
    category: "LamiGloss",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "AM 11010 HG - Black",
  },
  {
    id: 36,
    name: "AM 11014 HG-Metallic Silver",
    price: 101,
    image: image36,
    img: img36,
    imgg: imagg36 ,
    category: "LamiGloss",
    names: "Hardware",
    Description: newDescription,
    Description2: newDescription2,
    slug: "AM 11014 HG - Metallic Silver",
  },


]