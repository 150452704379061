
import React from "react";
import { useNavigate } from "react-router-dom";

const ProductCard = (props) => {
  const { name, image, slug } = props.data;
  const navigate = useNavigate();

  const handleClick = () => {
    window.scrollTo(0, 0);
    navigate(`/Layout/${slug}`);
  };

  return (
    <div className=" p-5 rounded-xl shadow-sm">
      <div onClick={handleClick} className="cursor-pointer">
        <img
          src={image}
          alt={name}
          className="w-full h-80 object-cover object-top drop-shadow-lg"
        />
      </div>
      <h3 className="text-2xl py-3 text-center font-medium text-black capitalize">
        {name}
      </h3>
    </div>
  );
};

export default ProductCard;
