import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { products } from "../Product";
import { FaPlus, FaTimes } from "react-icons/fa";

const Detail = () => {
  const { slug } = useParams();
  const [detail, setDetail] = useState(null);
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const findDetail = products.find((product) => product.slug === slug);
    if (findDetail) {
      setDetail(findDetail);
    } else {
      window.location.href = "/";
    }
  }, [slug]);

  if (!detail) return <div>Loading...</div>;

  const categories = [...new Set(products.map((product) => product.category))];

  const accordionData = [
    {
      title: "Framing Material",
      content: ["MDF", "HDHMR", "BWR Ply", "BWP Ply", "Bolio Board"],
    },
    {
      title: "Shutter Material",
      content: ["MDF", "HDHMR", "BWR Ply", "BWP Ply", "Bolio Board"],
    },
    {
      title: "Shutter Finish",
      content: [
        "Acrylic (Gloss or Matte)",
        "Poly Glass (Gloss or Matte)",
        "Laminate (Gloss or Matte)",
        "Veneer (Matte)",
        "PU (Gloss or Matte)",
      ],
    },
    {
      title: "Hardware",
      content: ["Laranza", "Hettice", "Hafele", "Blum", "Grass"],
    },
    {
      title: "Durability and Warranty",
      content: ["Wooden Material (5 Years)", "Hardware (10 Years)"],
    },
  ];

  const toggleAccordion = (index) => {
    setOpenAccordionIndex(openAccordionIndex === index ? null : index);
  };

  const handleCabinetClick = (slug) => {
    navigate(`/Layout/${slug}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="p-5 w-full bg-white">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Product Image */}
        <div className="flex justify-center items-center p-4">
          <div className="w-2/4 h-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl aspect-square rounded-2xl overflow-hidden shadow-lg border border-gray-300 bg-gray-100">
            <img
              src={detail.image}
              alt={detail.name}
              className="w-full h-full object-content rounded-2xl transition-transform duration-300 hover:scale-105"
            />
          </div>
        </div>

        {/* Product Details */}
        <div className="flex flex-col gap-5 p-4">
          <h1 className="text-4xl  font-bold uppercase text-black">
            {detail.name}
          </h1>
          <p className="text-lg text-gray-700 leading-relaxed">
            {detail.Description}
          </p>

          {detail.names && (
            <>
              <h2 className="text-3xl font-bold text-black">{detail.names}</h2>
              <p className="text-lg text-gray-700 leading-relaxed">
                {detail.Description2}
              </p>
            </>
          )}

          <Link to="/Layout">
            <button className="bg-gray-700 text-white px-6 py-3 rounded-lg shadow-md hover:bg-gray-800 transition-all">
              Back to Products
            </button>
          </Link>
        </div>
      </div>

      {detail.img && (
        <div className="mt-8 flex justify-center">
          <div className="w-full max-w-4xl h-auto rounded-2xl overflow-hidden shadow-xl">
            <img
              src={detail.img}
              alt={detail.name}
              className="w-full h-screen  object-containe rounded-2xl"
            />
          </div>
        </div>
      )}

      <div className="flex flex-col lg:flex-row p-6 gap-6">
        <div className="lg:w-3/6 w-full bg-gray-50 p-4 rounded-lg shadow-sm">
          <h2 className="text-6xl font-serif text-indigo-600 mb-4 mt-14">
            Stylish Kitchen Cabinets
          </h2>
          <p className="text-gray-600 mb-6 mt-10 text-2xl">
            Discover our elegant collection of kitchen cabinets that combine
            functionality and style to enhance your space.
          </p>
          {accordionData.map((item, index) => (
            <div key={index} className="mb-4 mt-10">
              <div
                className="flex justify-between items-center bg-gray-100 p-3 cursor-pointer rounded-lg shadow-sm"
                onClick={() => toggleAccordion(index)}
              >
                <span className="font-medium text-gray-700">{item.title}</span>
                {openAccordionIndex === index ? (
                  <FaTimes className="text-indigo-600" />
                ) : (
                  <FaPlus className="text-indigo-600" />
                )}
              </div>
              {openAccordionIndex === index && (
                <ul className="bg-white p-4 mt-2 rounded-lg shadow-sm space-y-2">
                  {item.content.map((line, i) => (
                    <li key={i} className="text-gray-600">
                      {line}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>

        <div className="lg:w-4/6 w-full">
          <div className="px-6">
            {categories.map((category, index) => (
              <div key={index} className="my-8">
                <h2 className="text-3xl font-semibold text-gray-700 mb-4">
                  {category}
                </h2>
                <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-6">
                  {products
                    .filter((product) => product.category === category)
                    .map((product) => (
                      <div
                        key={product.slug}
                        className="bg-white shadow-md rounded-lg overflow-hidden transition-transform transform hover:scale-105 cursor-pointer"
                        onClick={() => handleCabinetClick(product.slug)}
                      >
                        <img
                          src={product.image}
                          alt={product.name}
                          className="w-full h-60 object-cover rounded-t-lg"
                        />
                        {/* <div className="p-4">
                          <h3 className="text-lg font-bold text-gray-800">
                            {product.name}
                          </h3>
                          
                        </div> */}
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
