// import React from 'react'
// import { Route, Routes } from 'react-router-dom';
// import Layout from './Component/ProductListing/Layout';
// import Home from './Pages/Home';
// import Detail from './Pages/Detail';
// import "./App.css"
// import Landingpagess from './Component/Home/Landingpagess';
// import Contact from './Component/contact/Contact';
// import Gallery from './Component/Gallery/Gallery';



// function App() {
//   return (

    
//     <Routes>
//       <Route path='/' element={<Landingpagess/>} />
//       <Route path='/contact' element={<Contact/>} />
//       <Route path='/gallery' element={<Gallery/>} />
//       <Route path='/Layout' element={<Layout />}   >
//         <Route index element={<Home />} />
//         <Route path='/Layout/:slug' element={<Detail/>} />
//       </Route>
//     </Routes>

  
    
//   );
// }

// export default App;



import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./Component/ProductListing/Layout";
import Home from "./Pages/Home";
import Detail from "./Pages/Detail";
import "./App.css";
import Landingpagess from "./Component/Home/Landingpagess";
import Contact from "./Component/contact/Contact";
import Gallery from "./Component/Gallery/Gallery";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Landingpagess />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/Layout" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/Layout/:slug" element={<Detail />} />
      </Route>
    </Routes>
  );
}

export default App;

